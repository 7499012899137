import { useTranslation } from "react-i18next";

function ContactInfo() {
    const {t, i18n} = useTranslation()
    return(
    <div className="flex justify-center items-center flex-wrap lg:flex-nowrap gap-8 py-5 my-14">
        <div className="flex gap-x-5 items-center">
            <img src="/images/icons/SmartPhone.png" className="w-12 s:w-10" alt="" />
            <p className="text-sm md:text-base">{t("Phone")}: (+966)  59 200 0012</p>
        </div>
        <div className="flex gap-x-5 items-center">
            <img src="/images/icons/whatsapp1.png" className="w-12 s:w-10" alt="" />
            <p className="text-sm md:text-base"><a href="whatsapp://send?phone=+966592000012">{t("Whatsapp")}: (+966) 59 200 0012</a></p>
        </div>
        <div className="flex gap-x-5 items-center">
            <img src="images/icons/AddressBook.png" className="w-24 s:w-20" alt="" />
            <p className="text-sm md:text-base">info@mithaqaltashrie.com.sa</p>
        </div>
        <div className="flex gap-x-5 items-center">
            <img src="/images/icons/MeetingTime.png" className="w-12 s:w-10" alt="" />
            <p className="text-sm md:text-base">{t('ContactInfoTime')} <br/>{t('ContactInfoClosed')}</p>
        </div>
    </div>
    );
}
export default ContactInfo;