import { useTranslation } from "react-i18next";

function ArticleWriter(props) {
    const {t} = useTranslation();
    return(
        <>
            <div className="w-fit h-fit relative" >
                {/* <img src={"https://admin.mithaqaltashrie.com.sa//storage/"+props.image} className="relative rounded-[20px]"/> */}
                <img src="/images/team-1.png" className="relative rounded-[20px]" alt="" />
                <div className="h-full w-full top-0 absolute bg-gradient-to-t from-black rounded-[20px]"></div>
            </div>
            <div className="text-center mt-6">
                <p className="text-xl text-black">{props.writer}</p>
                <p className="font-light text-black">{t("Lawyer")}</p>
            </div>
        </>
    );
}
export default ArticleWriter;