import ArticleCard from "../components/ArticleCard";
function ArticleList(articles) {
    console.log(articles)
    return(
        <div className="grid grid-cols-3 gap-5 ">
            {articles && articles.articles.map((article)=>(
                <ArticleCard article={article} key={article.id}/>
                ))
            } 
        </div>
    )
}
export default ArticleList;