import ArticleCard from "./ArticleCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

function RelevantArticles() {
  const {t} = useTranslation();
    const [articles, setArticles]= useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.mithaqaltashrie.com.sa/api/articles');
            if (Array.isArray(response.data.articles)) {
              setArticles(response.data.articles.reverse().slice(0, 3));
            } else {
              console.error('API response is not an array');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData();
      }, []);
    return(
        <>
            <div className="w-100  text-[#21383d] text-[30px] font-semibold font-['Roboto'] mt-24">{t('articles-you-might-like')}</div>
            <div className="grid md:grid-cols-3 gap-4">
            {articles.map((article)=>(
                   <ArticleCard article={article}/>
                ))
            }

            </div>
        </>
    );
}
export default RelevantArticles