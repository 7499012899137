import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
function CategoryList() {
    const { t, i18n } = useTranslation();
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    useEffect(() => {
        const fetchCategories = async () => {
          try {
            const response = await axios.get('https://admin.mithaqaltashrie.com.sa/api/categories');
            setCategories(response.data.categories)
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchCategories();
      }, []);
    return (
        <ul className="bg-secondary rounded-[10px]">
          {console.log(categories)}
        {categories.map((category) => (
          <li
            key={category.id}
            className={`text-white px-4 py-2 rounded-lg  hover:bg-primary hover:text-secondary transition-all`}
            onClick={() => setSelectedCategory(category)}
          >
            <input type="checkbox" className="m-2"/>
            {i18n.language==='en'?category.name_en:category.name_ar}
          </li>
        ))}
      </ul>
    );
}
export default CategoryList