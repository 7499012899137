import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Home from "./pages";
import Contact from "./pages/Contact";
import Article from "./pages/Article";
import Articles from "./pages/Articles";
import About from "./pages/About";
import Services from "./pages/Services";
import Service from "./pages/Service";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const { t, i18n } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{t("website-title")}</title>
      </Helmet>
      <div className="App font-roboto" dir={i18n.language==='ar'?'rtl':'ltr'}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/service/:id" element={<Service />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/articles/:id" element={<Article/>} />
          <Route path="/articles" element={<Articles/>} />

        </Routes>
      </Router>
    </div>
    </HelmetProvider>
   
   
  );
}

export default App;
