import React from 'react'
import Menu from '../components/menu'
import Footer from '../components/footer'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Services() {
    const {t, i18n} = useTranslation();
    const services = [
        {
            id:"1",
            title : "bankruptcy",
            subTitle : "bankruptcy-subtitle",
            image : "/images/services/service-0.jpg",
            content :"bankruptcy-description"
        },
        {
            id:"2",
            title : "financial-claims-title",
            subTitle : "financial-claim-subtitle",
            image : "/images/services/service-1.jpg",
            content :"finantial-claim-description"
        },
        {
            id:"3",
            title : "commercial-arbitration-title",
            subTitle : "commercial-arbitration-subtitle",
            image : "/images/services/service-2.jpg",
            content :"commercial-arbitration-description"
        },
        {
            id:"4",
            title : "drafting-and-reviewing",
            subTitle : "darfting-subtitle",
            image : "/images/services/service-3.jpg",
            content :"dtafting-description"
        },
        {
            id:"5",
            title : "debt-collection",
            subTitle : "debt-subtitle",
            image : "/images/services/service-15.jpg",
            content :"debt-description"
        },
        {
            id:"6",
            title : "civil-cases",
            subTitle : "civil-cases-subtitle",
            image : "/images/services/service-4.jpg",
            content :"civil-cases-description"
        },
        {
            id:"7",
            title : "legal-risk-analysis",
            subTitle : "risk-analysis-subtitle",
            image : "/images/services/service-5.jpg",
            content :"risk-analysis-description"
        },
        {
            id:"8",
            title : "memorandums-and-regulations",
            subTitle : "memorandums-subtitle",
            image : "/images/services/service-6.jpg",
            content :"memorandums-description"
        },
        {
            id:"9",
            title : "documentation",
            subTitle : "doc_sub",
            image : "/images/services/service-7.jpg",
            content :"doc-desc"
        },
        {
            id:"10",
            title : "legal-consultations",
            subTitle : "consultation-subtitle",
            image : "/images/services/service-8.jpg",
            content :"consultation-description"
        },
        {
            id:"11",
            title : "corporate-governance",
            subTitle : "corporate-governance-subtitle",
            image : "/images/services/service-9.jpg",
            content :"corporate-governance-description"
        }
    ]
  return (
    <div>
        <Menu/>
        <div className="bg-secondary w-full relative h-48">
            <img src="/images/overlay-5.png" className='absolute right-0 bottom-0 z-0 w-[490px]' alt="" />
        </div>
        <div className='bg-white py-20 px-4 md:px-16'>
            <div className="flex justify-between flex-wrap">
                <p className="text-xl lg:text-4xl text-blue-dark font-semibold mb-4 md:mb-0">
                {t('our-expertise-2')} <br/>{t('solutions-tailored')}
                </p>
                <Link to="/contact" className='rounded-2xl bg-white text-secondary py-3 px-6 text-center border border-primary-dark h-fit flex gap-x-4'>
                    <CalendarDaysIcon className='w-5 h-5' />
                    <p className='text-sm'>{t("schedule-free-consultation")}</p>
                </Link>
            </div>
            <div className="flex flex-wrap gap-x-4 gap-y-9 mt-24 justify-center">
            {services.map((service)=>(
                  <Link to={"/service/"+service.id}>
                  <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                      <img src={service.image} className='h-52 rounded-2xl' alt="" />
                      <div className="mt-2 text-center">
                          <p className="text-xs font-medium text-white ">{t(service.title)}</p>
                      </div>
                  </div>
                  </Link>
                ))
                }

                {/* <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-0.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("bankruptcy")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-1.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("financial-claims")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-2.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("commercial-arbitration")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-3.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("drafting-and-reviewing")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-15.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("debt-collection")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-4.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("civil-cases")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-5.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("legal-risk-analysis")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-6.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("memorandums-and-regulations")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-7.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("documentation")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-8.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("legal-consultations")}</p>
                    </div>
                </div>
                </Link>
                <Link to={"/services/1"}>
                <div className="rounded-2xl bg-gradient-to-b from-gold to-primary-light p-2 w-72 flex flex-col justify-center">
                    <img src="/images/services/service-9.jpg" className='h-52 rounded-2xl' alt="" />
                    <div className="mt-2 text-center">
                        <p className="text-xs font-medium text-white uppercase">{t("corporate-governance")}</p>
                    </div>
                </div>
                </Link> */}
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Services